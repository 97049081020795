import node_modules__pnpm__64pinia_43nuxt_640_4_11_rollup_642_79_1_typescript_644_9_5_vue_643_3_13_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_O9iJl8oXe1 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@2.79.1_typescript@4.9.5_vue@3.3.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_53_0_rollup_642_79_1_stylus_640_59_0_typescript_644_9_5_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_Ziqv11lvVf from "/vercel/path0/node_modules/.pnpm/nuxt@3.0.0_eslint@8.53.0_rollup@2.79.1_stylus@0.59.0_typescript@4.9.5/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_53_0_rollup_642_79_1_stylus_640_59_0_typescript_644_9_5_node_modules_nuxt_dist_pages_runtime_router_mjs_Nycvk9G9pO from "/vercel/path0/node_modules/.pnpm/nuxt@3.0.0_eslint@8.53.0_rollup@2.79.1_stylus@0.59.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__pnpm_dayjs_nuxt_642_1_9_rollup_642_79_1_node_modules_dayjs_nuxt_dist_runtime_plugin_mjs_4lnPNOPGP2 from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@2.79.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import node_modules__pnpm__64nuxtjs_43device_643_1_1_rollup_642_79_1_node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_dwI2zYocqR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@2.79.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import node_modules__pnpm__64vueuse_43motion_642_0_0_rollup_642_79_1_vue_643_3_13_node_modules__64vueuse_motion_dist_runtime_templates_motion_mjs_TE4apoJ75k from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.0.0_rollup@2.79.1_vue@3.3.13/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugins_VercelAnalytics_client_ts_fYDHW4GFJl from "/vercel/path0/plugins/VercelAnalytics.client.ts";
import plugins_Vue3DaisyUI_client_ts_16MknYAcvF from "/vercel/path0/plugins/Vue3DaisyUI.client.ts";
import plugins_Vue3Marquee_client_ts_r0z0zv1iLW from "/vercel/path0/plugins/Vue3Marquee.client.ts";
import plugins_Vue3NovelEditor_client_ts_L1id9OXOhl from "/vercel/path0/plugins/Vue3NovelEditor.client.ts";
import plugins_apollo_client_ts_hklTlyn1Ww from "/vercel/path0/plugins/apollo.client.ts";
import plugins_autoanimate_ts_XXcG14nh7J from "/vercel/path0/plugins/autoanimate.ts";
import plugins_device_client_ts_IZWAmOdgTj from "/vercel/path0/plugins/device.client.ts";
import plugins_gesture_client_ts_Snq8tulmud from "/vercel/path0/plugins/gesture.client.ts";
import plugins_lottie_client_ts_MJBevkcINk from "/vercel/path0/plugins/lottie.client.ts";
import plugins_toast_client_ts_9z8IIRO0Dx from "/vercel/path0/plugins/toast.client.ts";
export default [
  node_modules__pnpm__64pinia_43nuxt_640_4_11_rollup_642_79_1_typescript_644_9_5_vue_643_3_13_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_O9iJl8oXe1,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_53_0_rollup_642_79_1_stylus_640_59_0_typescript_644_9_5_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_Ziqv11lvVf,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_53_0_rollup_642_79_1_stylus_640_59_0_typescript_644_9_5_node_modules_nuxt_dist_pages_runtime_router_mjs_Nycvk9G9pO,
  node_modules__pnpm_dayjs_nuxt_642_1_9_rollup_642_79_1_node_modules_dayjs_nuxt_dist_runtime_plugin_mjs_4lnPNOPGP2,
  node_modules__pnpm__64nuxtjs_43device_643_1_1_rollup_642_79_1_node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_dwI2zYocqR,
  node_modules__pnpm__64vueuse_43motion_642_0_0_rollup_642_79_1_vue_643_3_13_node_modules__64vueuse_motion_dist_runtime_templates_motion_mjs_TE4apoJ75k,
  plugins_VercelAnalytics_client_ts_fYDHW4GFJl,
  plugins_Vue3DaisyUI_client_ts_16MknYAcvF,
  plugins_Vue3Marquee_client_ts_r0z0zv1iLW,
  plugins_Vue3NovelEditor_client_ts_L1id9OXOhl,
  plugins_apollo_client_ts_hklTlyn1Ww,
  plugins_autoanimate_ts_XXcG14nh7J,
  plugins_device_client_ts_IZWAmOdgTj,
  plugins_gesture_client_ts_Snq8tulmud,
  plugins_lottie_client_ts_MJBevkcINk,
  plugins_toast_client_ts_9z8IIRO0Dx
]