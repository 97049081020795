<script setup>
import Toaster from '@/components/ui/toast/Toaster.vue'
useHead({
  title: 'Boost Your Documents with 1Page.so',
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/nuxt.png',
    },
  ],
  meta: [
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: 'Super-powered document creator',
    },
    {
      property: 'og:description',
      content:
        'Replace complex, back-and-forth email loops with supercharged documents to keep everyone on the same page.',
    },
    {
      property: 'og:image',
      content: '/thumbnail.jpg',
    },
    {
      name: 'keywords',
      content: 'Super-powered document creator',
    },
  ],
})

const nuxtApp = useNuxtApp()

// scrollToTop
nuxtApp.hook('page:finish', () => {
  window.scrollTo(0, 0)
})
</script>

<template lang="pug">
nuxt-layout
  nuxt-page
  Toaster
</template>

<style lang="stylus">
@import "./styles/style.styl";

html,
body,
#__nuxt
  height: 100vh
  margin: 0
  padding: 0


html.dark
  background: #222
  color: white

html
  scroll-behavior smooth /* set scroll-behaviour to smooth here */
  font-family 'Montserrat'
</style>
