import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core'
import {
  DefaultApolloClient,
  provideApolloClient,
} from '@vue/apollo-composable'

export default defineNuxtPlugin((nuxtApp) => {
  const token = useCookie('account')
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_APP_APOLLO_API_URL as string, // your endpoint
    headers: {
      'Authorization': token.value ? `Bearer ${token.value}` : '',
    },
  })

  const cache = new InMemoryCache()

  let apolloClient: ApolloClient<any>

  if (process.server) {
    apolloClient = new ApolloClient({
      ssrMode: true,
      link: httpLink,
      cache,
    })
    nuxtApp.hook('app:rendered', () => {
      nuxtApp.payload.data.apollo = apolloClient.extract()
    })
  }
  else {
    apolloClient = new ApolloClient({
      link: httpLink,
      cache,
    })
  }

  provideApolloClient(apolloClient)
  nuxtApp.provide('apollo', { DefaultApolloClient, apolloClient })
})
