import { default as _47vercel_47path0_47pages_47404_46vueMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta } from "/vercel/path0/pages/feed/[slug].vue?macro=true";
import { default as _47vercel_47path0_47pages_47feed_47index_46vueMeta } from "/vercel/path0/pages/feed/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47join_46vueMeta } from "/vercel/path0/pages/join.vue?macro=true";
import { default as _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta } from "/vercel/path0/pages/link/[slug].vue?macro=true";
import { default as _47vercel_47path0_47pages_47member_47index_46vueMeta } from "/vercel/path0/pages/member/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta } from "/vercel/path0/pages/page/[slug].vue?macro=true";
import { default as _47vercel_47path0_47pages_47page_47index_46vueMeta } from "/vercel/path0/pages/page/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47page_47new_46vueMeta } from "/vercel/path0/pages/page/new.vue?macro=true";
import { default as _47vercel_47path0_47pages_47setting_47index_46vueMeta } from "/vercel/path0/pages/setting/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47team_47index_46vueMeta } from "/vercel/path0/pages/team/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47upload_47index_46vueMeta } from "/vercel/path0/pages/upload/index.vue?macro=true";
import { default as _126_47pages_47link_47_91slug_93_46vueMeta } from "~/pages/link/[slug].vue?macro=true";
export default [
  {
    name: _47vercel_47path0_47pages_47404_46vueMeta?.name ?? "404",
    path: _47vercel_47path0_47pages_47404_46vueMeta?.path ?? "/404",
    file: "/vercel/path0/pages/404.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47404_46vueMeta,
    alias: _47vercel_47path0_47pages_47404_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47404_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta?.name ?? "feed-slug",
    path: _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta?.path ?? "/feed/:slug",
    file: "/vercel/path0/pages/feed/[slug].vue",
    children: [],
    meta: _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta,
    alias: _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47feed_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/feed/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47feed_47index_46vueMeta?.name ?? "feed",
    path: _47vercel_47path0_47pages_47feed_47index_46vueMeta?.path ?? "/feed",
    file: "/vercel/path0/pages/feed/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47feed_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47feed_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47feed_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47join_46vueMeta?.name ?? "join",
    path: _47vercel_47path0_47pages_47join_46vueMeta?.path ?? "/join",
    file: "/vercel/path0/pages/join.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47join_46vueMeta,
    alias: _47vercel_47path0_47pages_47join_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47join_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/join.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta?.name ?? "link-slug",
    path: _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta?.path ?? "/link/:slug",
    file: "/vercel/path0/pages/link/[slug].vue",
    children: [],
    meta: _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta,
    alias: _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47link_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/link/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47member_47index_46vueMeta?.name ?? "member",
    path: _47vercel_47path0_47pages_47member_47index_46vueMeta?.path ?? "/member",
    file: "/vercel/path0/pages/member/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47member_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47member_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47member_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/member/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta?.name ?? "page-slug",
    path: _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta?.path ?? "/page/:slug",
    file: "/vercel/path0/pages/page/[slug].vue",
    children: [],
    meta: _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta,
    alias: _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47page_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47page_47index_46vueMeta?.name ?? "page",
    path: _47vercel_47path0_47pages_47page_47index_46vueMeta?.path ?? "/page",
    file: "/vercel/path0/pages/page/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47page_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47page_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47page_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/page/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47page_47new_46vueMeta?.name ?? "page-new",
    path: _47vercel_47path0_47pages_47page_47new_46vueMeta?.path ?? "/page/new",
    file: "/vercel/path0/pages/page/new.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47page_47new_46vueMeta,
    alias: _47vercel_47path0_47pages_47page_47new_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47page_47new_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/page/new.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47setting_47index_46vueMeta?.name ?? "setting",
    path: _47vercel_47path0_47pages_47setting_47index_46vueMeta?.path ?? "/setting",
    file: "/vercel/path0/pages/setting/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47setting_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47setting_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47setting_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47team_47index_46vueMeta?.name ?? "team",
    path: _47vercel_47path0_47pages_47team_47index_46vueMeta?.path ?? "/team",
    file: "/vercel/path0/pages/team/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47team_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47team_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47team_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47upload_47index_46vueMeta?.name ?? "upload",
    path: _47vercel_47path0_47pages_47upload_47index_46vueMeta?.path ?? "/upload",
    file: "/vercel/path0/pages/upload/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47upload_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47upload_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47upload_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: _126_47pages_47link_47_91slug_93_46vueMeta?.name ?? "link-slug-name-domain",
    path: _126_47pages_47link_47_91slug_93_46vueMeta?.path ?? "/link/:slug/:name/:domain",
    alias: ["/l/:slug/:name/:domain"].concat(_126_47pages_47link_47_91slug_93_46vueMeta?.alias || []),
    file: "~/pages/link/[slug].vue",
    children: [],
    meta: _126_47pages_47link_47_91slug_93_46vueMeta,
    redirect: _126_47pages_47link_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("~/pages/link/[slug].vue").then(m => m.default || m)
  },
  {
    name: _126_47pages_47link_47_91slug_93_46vueMeta?.name ?? "link-slug-domain",
    path: _126_47pages_47link_47_91slug_93_46vueMeta?.path ?? "/link/:slug/:domain",
    alias: ["/l/:slug/:domain"].concat(_126_47pages_47link_47_91slug_93_46vueMeta?.alias || []),
    file: "~/pages/link/[slug].vue",
    children: [],
    meta: _126_47pages_47link_47_91slug_93_46vueMeta,
    redirect: _126_47pages_47link_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("~/pages/link/[slug].vue").then(m => m.default || m)
  }
]