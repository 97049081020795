import MobileDetect from 'mobile-detect'

export default defineNuxtPlugin((nuxtApp) => {
  const md = new MobileDetect(navigator.userAgent)
  const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
  const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
  const isDesktop = !isMobile && !isTablet

  nuxtApp.vueApp.provide('mobile-detect', {
    isMobile: () => isMobile,
    isTablet: () => isTablet,
    isDesktop: () => isDesktop,
  })
})
