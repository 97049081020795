import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  CaptureImage: defineAsyncComponent(() => import("/vercel/path0/components/global/CaptureImage.vue" /* webpackChunkName: "components/capture-image" */).then(c => c.default || c)),
  CommonInput: defineAsyncComponent(() => import("/vercel/path0/components/global/CommonInput.vue" /* webpackChunkName: "components/common-input" */).then(c => c.default || c)),
  CommonSelect: defineAsyncComponent(() => import("/vercel/path0/components/global/CommonSelect.vue" /* webpackChunkName: "components/common-select" */).then(c => c.default || c)),
  EditDrawer: defineAsyncComponent(() => import("/vercel/path0/components/global/EditLayout/EditDrawer.vue" /* webpackChunkName: "components/edit-drawer" */).then(c => c.default || c)),
  EditHeader: defineAsyncComponent(() => import("/vercel/path0/components/global/EditLayout/EditHeader.vue" /* webpackChunkName: "components/edit-header" */).then(c => c.default || c)),
  EditLayoutGetLink: defineAsyncComponent(() => import("/vercel/path0/components/global/EditLayout/GetLink.vue" /* webpackChunkName: "components/edit-layout-get-link" */).then(c => c.default || c)),
  EditLayoutRequiredField: defineAsyncComponent(() => import("/vercel/path0/components/global/EditLayout/RequiredField.vue" /* webpackChunkName: "components/edit-layout-required-field" */).then(c => c.default || c)),
  EditLayoutRequiredToView: defineAsyncComponent(() => import("/vercel/path0/components/global/EditLayout/RequiredToView.vue" /* webpackChunkName: "components/edit-layout-required-to-view" */).then(c => c.default || c)),
  FeedScreenTable: defineAsyncComponent(() => import("/vercel/path0/components/global/FeedScreen/Table.vue" /* webpackChunkName: "components/feed-screen-table" */).then(c => c.default || c)),
  GesturePlayer: defineAsyncComponent(() => import("/vercel/path0/components/global/GesturePlayer.vue" /* webpackChunkName: "components/gesture-player" */).then(c => c.default || c)),
  HomeDrawer: defineAsyncComponent(() => import("/vercel/path0/components/global/HomeLayout/HomeDrawer.vue" /* webpackChunkName: "components/home-drawer" */).then(c => c.default || c)),
  HomeHeader: defineAsyncComponent(() => import("/vercel/path0/components/global/HomeLayout/HomeHeader.vue" /* webpackChunkName: "components/home-header" */).then(c => c.default || c)),
  JoinScreenAuthenSectionV2: defineAsyncComponent(() => import("/vercel/path0/components/global/JoinScreen/AuthenSectionV2.vue" /* webpackChunkName: "components/join-screen-authen-section-v2" */).then(c => c.default || c)),
  LottiePlayer: defineAsyncComponent(() => import("/vercel/path0/components/global/LottiePlayer.vue" /* webpackChunkName: "components/lottie-player" */).then(c => c.default || c)),
  NovelEditor: defineAsyncComponent(() => import("/vercel/path0/components/global/NovelEditor.vue" /* webpackChunkName: "components/novel-editor" */).then(c => c.default || c)),
  PageScreenCustomInputScreen: defineAsyncComponent(() => import("/vercel/path0/components/global/PageScreen/CustomInputScreen.vue" /* webpackChunkName: "components/page-screen-custom-input-screen" */).then(c => c.default || c)),
  PageScreenNextStepForm: defineAsyncComponent(() => import("/vercel/path0/components/global/PageScreen/NextStepForm.vue" /* webpackChunkName: "components/page-screen-next-step-form" */).then(c => c.default || c)),
  PageScreenRequiredInput: defineAsyncComponent(() => import("/vercel/path0/components/global/PageScreen/RequiredInput.vue" /* webpackChunkName: "components/page-screen-required-input" */).then(c => c.default || c)),
  PageScreenTable: defineAsyncComponent(() => import("/vercel/path0/components/global/PageScreen/Table.vue" /* webpackChunkName: "components/page-screen-table" */).then(c => c.default || c)),
  PageScreenThanksScreen: defineAsyncComponent(() => import("/vercel/path0/components/global/PageScreen/ThanksScreen.vue" /* webpackChunkName: "components/page-screen-thanks-screen" */).then(c => c.default || c)),
  Pagination: defineAsyncComponent(() => import("/vercel/path0/components/global/Pagination.vue" /* webpackChunkName: "components/pagination" */).then(c => c.default || c)),
  ProfileBrandColor: defineAsyncComponent(() => import("/vercel/path0/components/global/Profile/BrandColor.vue" /* webpackChunkName: "components/profile-brand-color" */).then(c => c.default || c)),
  ProfileEditLogo: defineAsyncComponent(() => import("/vercel/path0/components/global/Profile/EditLogo.vue" /* webpackChunkName: "components/profile-edit-logo" */).then(c => c.default || c)),
  SearchBox: defineAsyncComponent(() => import("/vercel/path0/components/global/SearchBox.vue" /* webpackChunkName: "components/search-box" */).then(c => c.default || c)),
  SearchPageDialog: defineAsyncComponent(() => import("/vercel/path0/components/global/SearchPageDialog.vue" /* webpackChunkName: "components/search-page-dialog" */).then(c => c.default || c)),
  TeamScreenEmptyTeam: defineAsyncComponent(() => import("/vercel/path0/components/global/TeamScreen/EmptyTeam.vue" /* webpackChunkName: "components/team-screen-empty-team" */).then(c => c.default || c)),
  Icon: defineAsyncComponent(() => import("/vercel/path0/node_modules/.pnpm/nuxt-icon@0.1.8_rollup@2.79.1_vue@3.3.13/node_modules/nuxt-icon/dist/runtime/Icon.vue" /* webpackChunkName: "components/icon" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
